// import s from './Text.module.css'

// color={"--gold"}
// gradientColors={["--gradient-top", "--gradient-bot"]}

interface TextProps {
    children: React.ReactNode,
    fontSize: number,
    fontWeight?: number,
    lineHeight?: number,
    color?: string,
    gradientColors?: [string, string];
    width?: number,
    uppercase?: boolean,
    top?: number
}

const Text: React.FC<TextProps> = ({
    children,
    fontSize,
    fontWeight = 500,
    lineHeight,
    color = "--header-text",
    gradientColors,
    width,
    uppercase,
    top = 0,
}) => {

    return (
        <div style={{ position: "relative", textAlign: "center", width: `${width}vw`, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {/* Слой для тени */}
            <p
                style={{
                    fontSize: `${fontSize}rem`,
                    fontWeight: `${fontWeight}`,
                    lineHeight: lineHeight ? `${lineHeight}rem` : undefined,
                    color: `var(${color})`, // Цвет для тени
                    fontFamily: "Lilita One",
                    textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, 0 2px 0 #000, 1px 1px 0 #000",
                    position: "absolute",
                    top: top,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    textTransform: uppercase ? "uppercase" : "lowercase",
                    textAlign: "center"
                }}
            >
                {children}
            </p>
            {/* Слой для градиента */}
            <p
                style={{
                    fontSize: `${fontSize}em`,
                    fontWeight: `${fontWeight}`,
                    lineHeight: lineHeight ? `${lineHeight}em` : undefined,
                    color: "transparent",
                    background: gradientColors
                        ? `linear-gradient(to bottom, var(${gradientColors[0]}), var(${gradientColors[1]}))`
                        : undefined,
                    WebkitBackgroundClip: gradientColors ? "text" : undefined,
                    WebkitTextFillColor: "transparent",
                    fontFamily: "Lilita One",
                    position: "relative",
                    zIndex: 2,
                    textTransform: uppercase ? "uppercase" : "lowercase",
                }}
            >
                {children}
            </p>
        </div>
    );

}

export default Text

//свойство background - clip: text и WebkitTextFillColor: transparent делают текст прозрачным, 
//что приводит к тому, что text - shadow не отображается.
//свойства background - clip: text и text - shadow не работают вместе. 
// два слоя текста: один с тенью, а другой с градиентом