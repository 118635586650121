import React, { useState, useEffect, useRef } from "react";
import ItemListBG from "../common/ItemListBG";
import s from './Settings.module.css'
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { toggleMusic } from "../../redux/slices/gameSlice";
import { i18n } from "@lingui/core";

interface SettingsListProps {
    arrayItems: {
        title: string;
        btn1: string,
        btn2: string,
        btnBgOn: string,
        btnBgOff: string,
        icon: any;
    }[];
}

interface StateHandlers {
    isOn: boolean;
    setIsOn: React.Dispatch<React.SetStateAction<boolean>>;
    actionToggle?: () => void;
}

const SettingsList: React.FC<SettingsListProps> = ({ arrayItems }) => {
    const selectedLanguage = useSelector((state: RootState) => state.game.user.app_language || 'en');

    const isPlaying = useSelector((state: RootState) => state.game.isPlayingMusic);
    const dispatch = useDispatch();

    const toggleAudio = () => {
        dispatch(toggleMusic(!isPlaying));
    };

    const toggleLanguage = () => {
        const newLocale = selectedLanguage == 'en' ? 'ru' : 'en';
        i18n.activate(newLocale);

        dispatch({ type: 'game/setLanguageCode', payload: newLocale });
    }

    const getStateHandlers = (title: string): StateHandlers => {
        switch (title) {
            case "Sound":
                return { isOn: isPlaying, setIsOn: () => { }, actionToggle: toggleAudio };
            case "Language":
                return { isOn: selectedLanguage == 'en', setIsOn: () => { }, actionToggle: toggleLanguage };
            // case "Test":
            //     return { isOn: isOnTest, setIsOn: setIsOnTest };
            default:
                return { isOn: false, setIsOn: () => { } };
        }
    };

    const itemsWithState = arrayItems.map((item) => ({
        ...item,
        ...getStateHandlers(item.title),
    }));

    return (
        <div className={s.list}>

            {itemsWithState.map((item, index) => (
                <ItemListBG size={"big"} key={index} index={index} length={arrayItems.length}>
                    <img className={s.avaImg} src={item.icon} alt="avatar" />

                    <div className={s.textContainer}>
                        <p className={s.text} key={index}>
                            {i18n._(`${item.title}`)} {/* Переводим ключ */}
                        </p>
                    </div>

                    <button className={s.toggleBox}
                        style={{ justifyContent: `${item.isOn ? "flex-start" : "flex-end"}` }}
                        onClick={() => {
                            if (item.actionToggle) {
                                item.actionToggle();
                            } else {
                                item.setIsOn(!item.isOn);
                            }
                        }}
                    >
                        <div className={s.buttonBg}
                            style={{ backgroundImage: `url(${item.isOn ? item.btnBgOn : item.btnBgOff})` }}
                        >
                            <p className={s.text}>{item.isOn ? item.btn2 : item.btn1}</p>
                        </div>
                    </button>
                </ItemListBG>
            ))}
        </div >
    )
}

export default SettingsList