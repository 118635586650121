// src/pages/FriendsPage.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FrensList from '../../components/FrensList';
import s from "./FriendsPage.module.css"
import SimpleButton from '../../components/common/SimpleButton';
import { telegramShare } from "../../services/telegram";
import PageContainer from '../../components/common/PageContainer';
import ImageComponent from '../../components/common/ImageComponent';
import ImageComponentBG from '../../components/common/ImageComponentBG';
import { showToast } from "../../redux/slices/gameSlice";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";

const FriendsPage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.game.user);
    // fixme: change to global db config
    const rewardPerFriend = 350;
    const { link, handleGenerateLink } = telegramShare(user);

    const [friendsCount, setFriendsCount] = useState<number>(0);
    const [tokensCount, setTokensCount] = useState<number>(0);
    const [level, setLevel] = useState<number>(0);

    const level1Friends = user?.referrals && user?.referrals[1] ? user?.referrals[1] : 0;
    const level2Friends = user?.referrals && user?.referrals[2] ? user?.referrals[2] : 0;
    const level3Friends = user?.referrals && user?.referrals[3] ? user?.referrals[3] : 0;

    const level1Tokens = user?.referrals_tokens && user?.referrals_tokens[1] ? user?.referrals_tokens[1] : 0;
    const level2Tokens = user?.referrals_tokens && user?.referrals_tokens[2] ? user?.referrals_tokens[2] : 0;
    const level3Tokens = user?.referrals_tokens && user?.referrals_tokens[3] ? user?.referrals_tokens[3] : 0;

    useEffect(() => {
        if (!user.referrals) return;
        setFriendsCount(level1Friends + level2Friends + level3Friends);
    }, [user.referrals]);

    useEffect(() => {
        if (!user.referrals_tokens) return;
        setTokensCount(level1Tokens + level2Tokens + level3Tokens);
    }, [user.referrals_tokens]);


    // const { friends } = useSelector((state: RootState) => state.game);

    const handleButtonClick = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                dispatch(showToast('Copied to clipboard'));
            })
            .catch(err => {
            });
    };
    // ======= Tabs
    const [activeTab, setActiveTab] = useState(0)

    const tabs = [
        {
            id: 0, title: t`Total`, bg: `/images/frens/tabs_btn.webp`, tabFunc: (id: number) => {
                if (user.referrals) setFriendsCount(level1Friends + level2Friends + level3Friends);
                if (user.referrals_tokens) setTokensCount(level1Tokens + level2Tokens + level3Tokens);
                setLevel(0);
            }
        },
        {
            id: 1, title: t`1st line`, bg: `/images/frens/tabs_btn.webp`, tabFunc: (id: number) => {
                if (user.referrals) setFriendsCount(level1Friends);
                if (user.referrals_tokens) setTokensCount(level1Tokens);
                setLevel(1);
            }
        },
        {
            id: 2, title: t`2nd line`, bg: `/images/frens/tabs_btn.webp`, tabFunc: (id: number) => {
                if (user.referrals) setFriendsCount(level2Friends);
                if (user.referrals_tokens) setTokensCount(level2Tokens);
                setLevel(2);
            }
        },
        {
            id: 3, title: t`3rd line`, bg: `/images/frens/tabs_btn.webp`, tabFunc: (id: number) => {
                if (user.referrals) setFriendsCount(level3Friends);
                if (user.referrals_tokens) setTokensCount(level3Tokens);
                setLevel(3);
            }
        },
    ];

    const handleTabClick = (id: number, tabFunc: (id: number) => void) => {
        setActiveTab(id);
        tabFunc(id);
    };

    return (
        <>
            <PageContainer>
                <ImageComponentBG className={s.baseTabs} imageUrl={`/images/frens/tabs_base.webp`}>
                    <div className={s.tabsContainer}>
                        {tabs.map((tab) => (
                            <SimpleButton
                                key={tab.id}
                                className={s.btnTabs}
                                onClick={() => handleTabClick(tab.id, tab.tabFunc)}
                                imageUrl={activeTab === tab.id ? tab.bg : ""}
                            >
                                <p className={s.btnTabsText}>{tab.title}</p>
                            </SimpleButton>
                        ))}
                    </div>
                </ImageComponentBG>

                <div className={s.baseBox}>
                    <ImageComponentBG className={`${s.base} ${s.left} ${s.animateLeft}`} imageUrl={`/images/frens/frens_invited_base.webp`}>
                        <div className={s.textBox}>
                            <p className={`${s.text} ${s.frensRotate}`}><Trans>Frens invited</Trans></p>
                            <p className={s.lowerText}>{friendsCount}</p>
                        </div>
                        <ImageComponent className={s.baseImg} src={`/images/tabbar/ic_tabbar_frens_act.webp`} alt="frens" />
                    </ImageComponentBG>


                    <ImageComponentBG className={`${s.base} ${s.right} ${s.animateRight}`} imageUrl={`/images/frens/frens_earned_base.webp`}>
                        <ImageComponent className={s.baseImg} src={`/images/header/coin.webp`} alt="coin" />
                        <div className={s.textBox}>
                            <p className={`${s.text} ${s.earnedRotate}`}><Trans>Baa earned</Trans></p>
                            <p className={s.lowerText}>{/*{firstLineFriends * rewardPerFriend}*/}{tokensCount}</p>
                        </div>
                    </ImageComponentBG>
                </div>
                {/* ================ */}
                <ImageComponentBG className={s.titleOnBase} imageUrl={"/images/frens/frens_list_base.webp"}>
                    <p className={s.btnTabsText}><Trans>Frens list</Trans></p>
                </ImageComponentBG>


                {friendsCount !== 0
                    ? <FrensList totalFriends={friendsCount} level={level} />
                    : <div className={s.noFriendsWrapper}>
                        <img className={s.noFriendsImg} src={"/images/frens/no_friends.webp"} alt="no_friends" />
                    </div>
                }

                {/* ==================== */}

                <ImageComponentBG className={s.referBase} imageUrl={"/images/frens/refer_base.webp"}>
                    <p className={s.textReferalTop}><Trans>Refer a fren and earn</Trans></p>
                    <div className={s.refCoinBox}>
                        <ImageComponent className={s.refCoinImg} src={`/images/header/coin.webp`} alt="coin" />
                        <p className={s.textReferalEarn}>{rewardPerFriend} BAA</p>
                    </div>
                    <p className={s.textReferal}><Trans>for you and your friends</Trans></p>
                    <div className={s.refBtnsBox}>
                        <SimpleButton className={`${s.referalBtns} ${s.inviteFriends}`} onClick={handleGenerateLink} type="button" imageUrl={`/images/frens/invite_friends_btn.webp`}>
                            <p className={s.textReferalBtn}><Trans>Invite friends</Trans></p>
                        </SimpleButton>
                        {/* <SimpleButton imageUrl={`/images/frens/copy_btn.webp`} width={13.33} onClick={() => navigator.clipboard.writeText(link)} /> */}
                        <SimpleButton imageUrl={`/images/frens/copy_btn.webp`} width={13.33} onClick={handleButtonClick} />
                    </div>
                </ImageComponentBG>

            </PageContainer>
        </>
    );
};

export default FriendsPage;
