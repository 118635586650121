// import CheatList
import CheatListItem from './CheatListItem';
import s from './CheatList.module.css'
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import React, { useState } from "react";
import { Task } from "../../redux/types";
import Modal from "../common/Modal";
import ModalTasksContent from "../ModalTasksContent";
import { Trans } from "@lingui/react/macro";

const CheatList: React.FC = () => {
    const tasks = useSelector((state: RootState) => state.game.user.tasks);

    const cheatedList = Object.entries(tasks).filter(([id, task]) => {
        return task.status === 0;
    });

    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const openModal = (task: Task | undefined) => {
        if (!task) return;

        setSelectedTask(task);
    };
    const closeModal = () => {
        setSelectedTask(null);
    };

    return (
        <div className={s.list}>
            <div className={s.titleBox}>
                <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
                <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
                <p className={s.titleHeader}><Trans>ooops</Trans></p>
            </div>

            <h1 className={s.pageTitleText}><Trans>Looks like you decided to cheat!</Trans></h1>
            <p className={s.titleText}><Trans>To continue the game, you need to complete the following tasks again</Trans></p>

            <div className={s.listBox}>
                {cheatedList.map(([id, value], key) => (
                    <CheatListItem onTaskClick={openModal} key={key} item={value} taskId={Number(id)} isLast={key === Number(cheatedList[cheatedList.length - 1][0])} />
                ))}
            </div>

            {selectedTask && <Modal closeModal={closeModal} blocking={true}>
                <ModalTasksContent task={selectedTask} closeModal={closeModal} />
            </Modal>}
        </div >
    );
};

export default CheatList