import SimpleButton from '../common/SimpleButton'
import s from './ModalPromoLimit.module.css'
import React from "react";
import { Trans } from "@lingui/react/macro";

interface ModalPromoLimitProps {
    closeModal: () => void;
}

const ModalPromoLimit: React.FC<ModalPromoLimitProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.caseImg} src={`/images/promos/broken_heart.webp`} alt="case" />

            <h1 className={s.textTitle}><Trans>ENOUGH!</Trans></h1>

            <div className={s.textBox}>
                <p className={s.text}><Trans>You used all attempts. Try tomorrow</Trans></p>
                <img className={s.divider} src={`/images/modalTasksContent/divider.webp`} alt="devider" />
            </div>
            <div className={s.textBox}>
                <p className={s.text}><Trans>Get the secret code from a friend,</Trans></p>
                <p className={s.text}><Trans>on the official $BAA pages</Trans></p>
                <p className={s.text}><Trans>or on partner channels</Trans></p>
            </div>

            <button className={s.btn} onClick={() => {
                closeModal()
            }} type="button">
                <p className={s.text}>OK</p>
            </button>
        </div>
    )
}

export default ModalPromoLimit