import Modal from "../common/Modal";
import useModal from "../../services/useModal";
import SimpleButton from "../common/SimpleButton"
import s from "./AutotapAirdrop.module.css"
import ModalAirdropContent from "../ModalAirdropContent";
import ModalHotOfferContent from "../ModalHotOfferContent";
import ModalAutoTapContent from "../ModalAutotapContent";
import React, { useState, useEffect } from "react";
import ModalEnergyIncreaseContent from "../ModalEnergyIncreaseContent";
import { setCurrentModal, setDismissedTaskPopup } from "../../redux/slices/gameSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Trans } from "@lingui/react/macro";

interface AutoTapProps {
    address: string;
    tapPower: number;
    autoTapSeconds: number;
    rewardSum: number;
    dismissPopup: boolean;
}

const AutotapAirdrop: React.FC<AutoTapProps> = ({ address, tapPower, autoTapSeconds, rewardSum, dismissPopup }) => {

    const dispatch = useDispatch();
    const isAutotap = autoTapSeconds > 0
    const isAirdrop = address !== '' && typeof address !== 'undefined';

    let hoursLeft, minutesLeft;

    if (isAutotap) {
        hoursLeft = String(Math.floor(autoTapSeconds / 3600)).padStart(2, '0')
        minutesLeft = String(Math.floor((autoTapSeconds % 3600) / 60)).padStart(2, '0')
    }

    const airdropImg = isAirdrop ? '/images/game_check.webp' : '/images/game_uncheck.webp'

    // ==== MODAL Airdrop=====
    const { isOpen: isModalOpenAirdrop,
        isFadingOut: isFadingOutAirdrop,
        openModal: openModalAirdrop,
        closeModal: closeModalAirdrop } = useModal();

    // ==== MODAL Autotap=====
    const { isOpen: isModalOpenAutotap,
        isFadingOut: isFadingOutAutotap,
        openModal: openModalAutotap,
        closeModal: closeModalAutotap } = useModal();

    // ==== MODAL HotOffer=====
    const { isOpen: isModalOpenHotOffer,
        isFadingOut: isFadingOutHotOffer,
        openModal: openModalHotOffer,
        closeModal: closeModalHotOffer } = useModal();

    // ==== MODAL EnergyIncrease=====
    const { isOpen: isModalOpenEnergyIncrease,
        isFadingOut: isFadingOutEnergyIncrease,
        openModal: openModalEnergyIncrease,
        closeModal: closeModalEnergyIncrease } = useModal();



    const [buttonWidth, setButtonWidth] = useState(18);

    useEffect(() => {
        const updateButtonWidth = () => {
            const screenHeight = window.innerHeight;
            setButtonWidth(screenHeight < 660 ? 18 : 24);
        };

        updateButtonWidth(); // Инициализация при монтировании
        window.addEventListener("resize", updateButtonWidth); // Обновление при изменении размера окна

        return () => {
            window.removeEventListener("resize", updateButtonWidth); // Удаление слушателя при размонтировании
        };
    }, []);

    useEffect(() => {
        if (dismissPopup || rewardSum === 0) return;
        openModalHotOffer();
    }, [rewardSum, dismissPopup]);

    const onCloseTaskModal = () => {
        dispatch(setDismissedTaskPopup(true));
        closeModalHotOffer();
    }

    const onOpenTaskModal = () => {
        closeModalHotOffer();
        dispatch(setCurrentModal("tasks"));
        dispatch(setDismissedTaskPopup(true));
    }

    return (
        <div className={s.container}>
            <SimpleButton className={s.questionBtn} onClick={openModalEnergyIncrease}>
                <div className={s.tapContainer}>
                    <img className={s.coinImg} src={'/images/header/coin.webp'} alt="coin" />
                    <p className={s.tapText}>+{tapPower} <Trans>per tap</Trans></p>
                    <img className={s.coinImg} src={'/images/game_question.webp'} alt="question" />
                </div>
            </SimpleButton>

            <div className={s.buttonsContainer}>
                <div className={`${s.buttonBox} ${s.absoluteLeft}`}>
                    {/* todo: display auto-tap when ready  */}
                    <SimpleButton imageUrl={`/images/game_autotap_img.webp`} width={buttonWidth} onClick={openModalAutotap} />
                    <img className={s.autotapTextImg} src={'/images/game_autotap.webp'} alt="autotap" />

                    {isAutotap ?
                        <div className={s.timeBox}>
                            {hoursLeft}H {minutesLeft}M
                        </div>
                        : <img className={s.autotapCheck} src={'/images/game_uncheck.webp'} alt="check" />}
                </div>

                <div className={`${s.buttonBox} ${s.absoluteRight}`}>
                    <SimpleButton imageUrl={`/images/game_airdrop_img.webp`} width={buttonWidth} onClick={openModalAirdrop} />
                    <img className={s.airdtopTextImg} src={'/images/game_airdrop.webp'} alt="airdrop" />
                    <img className={s.airdtopCheck} src={airdropImg} alt="airdropImg" />
                </div>
            </div>

            {isModalOpenAirdrop && <Modal closeModal={closeModalAirdrop} isFadingOut={isFadingOutAirdrop} blocking={true}>
                <ModalAirdropContent existingAddress={address} closeModal={closeModalAirdrop} />
            </Modal>}

            {isModalOpenAutotap && <Modal closeModal={closeModalAutotap} isFadingOut={isFadingOutAutotap} blocking={true}>
                <ModalAutoTapContent closeModal={closeModalAutotap} />
            </Modal>}

            {isModalOpenHotOffer && <Modal closeModal={onCloseTaskModal} isFadingOut={isFadingOutHotOffer} blocking={true}>
                <ModalHotOfferContent rewardSum={rewardSum} onClick={onOpenTaskModal} closeModal={onCloseTaskModal} />
            </Modal>}

            {isModalOpenEnergyIncrease && <Modal closeModal={closeModalEnergyIncrease} isFadingOut={isFadingOutEnergyIncrease} blocking={true}>
                <ModalEnergyIncreaseContent tapPower={tapPower} onClick={() => { closeModalEnergyIncrease(); dispatch(setCurrentModal('boost')) }} closeModal={closeModalEnergyIncrease} />
            </Modal>}
        </div >
    )
}


export default AutotapAirdrop