import riveWASMResource from "@rive-app/canvas/rive.wasm";
import { useRive, Layout, Fit, Alignment, RuntimeLoader } from "@rive-app/react-canvas";
import s from "./GamePage.module.css"
import React, { useEffect, useState } from "react";

RuntimeLoader.setWasmUrl(riveWASMResource);

interface RiveWrapperProps {
    energy: number;
    onClick: () => void;
    autoTapEnabled: boolean;
    tapPower: number;
}

interface Point {
    id: number;
    x: number;
    y: number;
}

// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const RiveWrapper: React.FC<RiveWrapperProps> = ({ onClick, energy, autoTapEnabled, tapPower }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const { rive, RiveComponent } = useRive({
        src: '/anim/sheep.riv',
        stateMachines: "idle",
        autoplay: true,
        onLoad: () => { setIsLoaded(true) },
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center,
        })
    });


    useEffect(() => {
        if (!autoTapEnabled || !rive) return;

        const interval = setInterval(() => {
            if (rive) {
                rive.play('boo')
            }
        }, 500);

        return () => clearInterval(interval);
    }, [autoTapEnabled, rive]);

    //==== FLYING +1 =====================
    const [points, setPoints] = useState<Point[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const containerRect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - containerRect.left;
        const y = event.clientY - containerRect.top;

        const newPoint: Point = {
            id: Date.now(), // Unique ID for each "+1"
            x,
            y,
        };

        setPoints((prevPoints) => [...prevPoints, newPoint]);

        // Remove the "+1" after the animation ends (1 second)
        setTimeout(() => {
            setPoints((prevPoints) => prevPoints.filter((point) => point.id !== newPoint.id));
        }, 1000);
    };

    const combinedClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!rive || !energy || autoTapEnabled) return;
        rive.stop('scissor');
        rive.play('scissor');
        rive.play('boo');
        handleClick(event);
        onClick();
    };


    return <div className={`${s.riveComponentContainer} ${isLoaded ? s.visible : ''}`}>
        <div className={s.containerFlying} onClick={combinedClickHandler}>
            {points.map((point) => (
                <div
                    key={point.id}
                    className={s.floatingFlying}
                    style={{
                        left: point.x + "px",
                        top: point.y + "px",
                    }}
                >
                    <div className={s.gradientBox}>
                        <p className={s.textShadow}>+{tapPower}</p>
                        <p className={s.textGradient}>+{tapPower}</p>
                    </div>
                </div>
            ))}
        </div>

        <RiveComponent
            // onTouchStart={(event: React.TouchEvent<HTMLCanvasElement>) => {
            //     event.stopPropagation();
            //     if (!rive || !energy || autoTapEnabled) return;
            //     rive.stop('scissor');
            //     rive.play('scissor');
            //     rive.play('boo');
            //     onClick();
            // }}
            // style={{ width: '100%', height: '80%', paddingLeft: '2.42vw' }}
            className={s.riveComponent}
        />

    </div>;
};

export default RiveWrapper;
