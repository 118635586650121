import SimpleButton from '../common/SimpleButton'
import s from './ModalChannelContent.module.css'
import telegramWebAppAPI from "../../services/telegram";
import React from "react";
import { Trans } from "@lingui/react/macro";

interface ModalChannelContentProps {
    closeModal: () => void;
}

const ModalChannelContent: React.FC<ModalChannelContentProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.caseImg} src={`/images/task_channel_modal.webp`} alt="case" />

            <h1 className={s.textTitle}><Trans>FOR PARTNERS</Trans></h1>

            <div className={s.textBox}>
                <p className={s.text}><Trans>Do you want to advertise your Telegram</Trans></p>
                <p className={s.text}><Trans>channel or mini app</Trans>?</p>
                <p className={s.text}><Trans>FeeL FREE TO MESSAGE US</Trans></p>
            </div>

            <button className={s.btn} onClick={() => { telegramWebAppAPI.openTelegramLink("https://t.me/BaaTeam") }} type="button">
                <p className={s.text}><Trans>Message</Trans></p>
            </button>
        </div>
    )
}

export default ModalChannelContent