import s from './About.module.css'
import React from "react";
import ItemListBG from '../common/ItemListBG';
import { i18n } from "@lingui/core";

interface AboutListProps {
    arrayItems: {
        title: string;
        text: string;
        icon: any;
    }[];
}

const AboutList: React.FC<AboutListProps> = ({ arrayItems }) => {
    const getListItemClassNamePos = (index: number) => {
        switch (index) {
            case 0:
                return s.goldPos;
            case 1:
                return s.silverPos;
            case 2:
                return s.bronzePos;
            default:
                return s.lowRatingPos;
        }
    };

    return (

        // <div className={s.ItemListBG}>
        <>
            {arrayItems.map((item, index) => (
                <ItemListBG size={"big"} key={index} index={index} length={arrayItems.length} >
                    <img className={s.avaImg} src={item.icon} alt="avatar" />

                    <div className={s.textContainer}>
                        <p className={`${s.titleList} ${getListItemClassNamePos(index)}`} key={index + '-0'}>
                            {/* {item.title} */}
                            {i18n._(`${item.title}`)}
                        </p>
                        <p className={s.text} key={index + '-1'}>
                            {/* {item.text} */}
                            {i18n._(`${item.text}`)}
                        </p>
                    </div>
                </ItemListBG>
            ))}
        </>
        // </div >
    )
}

export default AboutList