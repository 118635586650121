// src/redux/slices/gameSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {BoostStatus, TaskStatus, Tokens, User} from '../types';
interface GameState {
    user: User;
    // friends: User[];
    taps: number[],
    authTokens: Tokens;
    isAuthorized: boolean,
    isLoaded: boolean,
    loadProgress: number,
    currentModal: string;
    secondaryModal: string;
    runningRequest: boolean;
    toast: string;
    lastSyncAt: number;
    isNetworkError: boolean;
    sendRequestFunc: string,
    sendRequestData: {},
    showPromo: number;
    isPlayingMusic: boolean;
    dismissedTasksPopup: boolean;
    // Add other relevant state properties here
}

const initialState: GameState = {
    user: {id: 0, avatar: '', airdrop_address: '', promos_claimed: [], promo_attempts: 0, tokens: 0, energy: 0, max_energy: 0, username: '', boosts: {}, tasks: {}, language_code: '', position: 0, reward: 0, is_active: false, ref_code: '', friends: {}, completed_tasks: [], claimed_boosts: [], referrals: {}, referrals_tokens: {}, ref_level: 0, app_language: 'en'},
    // friends: [],
    taps: [],
    authTokens: {access_token: '', refresh_token: '', expires_in: 0},
    isAuthorized: false,
    isLoaded: false,
    loadProgress: 0,
    currentModal: "",
    secondaryModal: '',
    runningRequest: false,
    toast: '',
    lastSyncAt: new Date().getTime(),
    isNetworkError: false,
    sendRequestFunc: '',
    sendRequestData: {},
    showPromo: 0,
    isPlayingMusic: false,
    dismissedTasksPopup: false,
};

const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload;
        },
        showPromo(state, action: PayloadAction<number>) {
            if(action.payload === 0)
                state.showPromo = action.payload;
            else
                state.showPromo += 1;
        },
        // setFriends(state, action: PayloadAction<User[]>) {
        //     state.friends = action.payload;
        // },
        setIsNetworkError(state, action: PayloadAction<boolean>) {
            state.isNetworkError = action.payload;
        },
        setSendRequestFunc(state, action: PayloadAction<string>) {
            state.sendRequestFunc = action.payload;
        },
        setSendRequestData(state, action: PayloadAction<{}>) {
            state.sendRequestData = action.payload;
        },
        setTaps(state, action: PayloadAction<number>) {
            const tapPower = state.user.tap_power || 1;

            state.user.tokens += (action.payload * tapPower);
            state.user.energy -= (action.payload * tapPower);
            state.taps.push(Math.floor(new Date().getTime() / 1000));
        },
        addCompletedTask(state, action: PayloadAction<number>) {
            state.user.completed_tasks.push(action.payload);
        },
        setBoostStatus(state, action: PayloadAction<{[key: number]: BoostStatus}>) {
            state.user.boosts = action.payload;
        },
        setPosition(state, action: PayloadAction<number>) {
            state.user.position = action.payload;
        },
        setMaxEnergy(state, action: PayloadAction<number>) {
            state.user.max_energy = action.payload;
        },
        setAvatar(state, action: PayloadAction<string>) {
            state.user.avatar = action.payload;
        },
        setTaskStatus(state, action: PayloadAction<{[key: number]: TaskStatus}>) {
            state.user.tasks = action.payload;
        },
        setCompletedTasks(state, action: PayloadAction<number[]>) {
            state.user.completed_tasks = action.payload;
        },
        setAuthTokens(state, action: PayloadAction<Tokens>) {
            state.authTokens = action.payload;
        },
        setEnergy(state, action: PayloadAction<number>) {
            state.user.energy = action.payload;
        },
        setTokens(state, action: PayloadAction<number>) {
            state.user.tokens = action.payload;
        },
        resetTaps(state) {
            state.taps = [];
        },
        restoreTaps(state, action: PayloadAction<number[]>) {
            state.taps = [...action.payload, ...state.taps];
        },
        setPromoAttempts(state, action: PayloadAction<number>) {
            state.user.promo_attempts = action.payload;
        },
        setAuthorization(state, action: PayloadAction<boolean>) {  // Add this action
            state.isAuthorized = action.payload;
        },
        setIsLoaded(state, action: PayloadAction<boolean>) {
            state.isLoaded = action.payload;
        },
        setLoadProgress(state, action: PayloadAction<number>) {
            state.loadProgress = action.payload;
        },
        setCurrentModal(state, action: PayloadAction<string>) {
            state.currentModal = action.payload;
        },
        setRunningRequest(state, action: PayloadAction<boolean>) {
            state.runningRequest = action.payload;
        },
        showToast(state, action: PayloadAction<string>) {
            state.toast = action.payload;
        },
        setLastSyncAt(state, action: PayloadAction<number>) {
            state.lastSyncAt = action.payload;
        },
        setClaimedPromos(state, action: PayloadAction<number[]>) {
            state.user.promos_claimed = action.payload;
        },
        setAddress(state, action: PayloadAction<string>) {
            state.user.airdrop_address = action.payload;
        },
        setAutoTapSeconds(state, action: PayloadAction<number>) {
            state.user.auto_tap_seconds = action.payload;
        },
        increaseTokens(state, action: PayloadAction<number>) {
            state.user.tokens += action.payload;
        },
        decreaseEnergy(state, action: PayloadAction<number>) {
            state.user.energy -= action.payload;
        },
        toggleMusic(state, action: PayloadAction<boolean>) {
            state.isPlayingMusic = action.payload;
        },
        setSecondaryModal(state, action: PayloadAction<string>) {
            state.secondaryModal = action.payload;
        },
        setDismissedTaskPopup(state, action: PayloadAction<boolean>) {
            state.dismissedTasksPopup = action.payload;
        },
        setAppLanguage(state, action: PayloadAction<string>) {
            state.user.app_language = action.payload;
        }
    },
});

export const { showPromo, setSendRequestFunc, setSendRequestData, setIsNetworkError, setLastSyncAt, setAvatar, setMaxEnergy, setPosition, setClaimedPromos, setPromoAttempts,showToast, setCompletedTasks, setRunningRequest,setBoostStatus, setTaskStatus, addCompletedTask, /*setFriends,*/ setAuthorization, setCurrentModal, setLoadProgress, setIsLoaded, resetTaps,setUser, setTaps, setEnergy,setAuthTokens, restoreTaps ,setTokens, setAddress, setAutoTapSeconds, increaseTokens, decreaseEnergy, toggleMusic, setSecondaryModal, setDismissedTaskPopup, setAppLanguage } = gameSlice.actions;
export default gameSlice.reducer;
