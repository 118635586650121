import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";
import s from "./Header.module.css";
import SimpleButton from "../common/SimpleButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Modal from "../common/Modal";
import About from "../About";
import ModalChannelContent from "../ModalChannelContent";
import useModal from "../../services/useModal";
import SecretCode from "../SecretCode";
import { setSecondaryModal, showPromo } from "../../redux/slices/gameSlice";
import Settings from "../Settings";
import ModalRulesContent from "../ModalRulesContent";
import ModalBoostBuyingContent from "../ModalBoostBuyingContent";
import { telegramShare } from "../../services/telegram";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Root } from "react-dom/client";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const currentModal = useSelector((state: RootState) => state.game.currentModal);
  const secondaryModal = useSelector((state: RootState) => state.game.secondaryModal);
  const { user } = useSelector((state: RootState) => state.game);
  const isPlayingMusic = useSelector((state: RootState) => state.game.isPlayingMusic);

  const audioContainerRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Create the audio element
    const audio = document.createElement('audio');
    audio.loop = true;

    // Create source elements for .ogg and .mp3
    const sourceOgg = document.createElement('source');
    sourceOgg.src = '/audio/bg.ogg';
    sourceOgg.type = 'audio/ogg';

    const sourceMp3 = document.createElement('source');
    sourceMp3.src = '/audio/bg.mp3';
    sourceMp3.type = 'audio/mpeg';

    // Append sources to the audio element
    audio.appendChild(sourceOgg);
    audio.appendChild(sourceMp3);

    // Store a reference to the audio element
    audioRef.current = audio;

    // Capture the current value of the ref
    const audioContainer = audioContainerRef.current;
    if (audioContainer) {
      audioContainer.appendChild(audio);
    }

    // Cleanup on unmount
    return () => {
      audio.pause();
      if (audioContainer) {
        audioContainer.removeChild(audio);
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlayingMusic) {
        audioRef.current.play().catch(() => { });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlayingMusic, audioRef.current]);

  const isFriendsLeaderboard = useSelector((state: RootState) => state.leaderboard.isFriendsLeaderboard);

  const [modalTitle, setModalTitle] = useState('')
  const [modalDescription, setModalDescription] = useState('');


  useEffect(() => {
    if (secondaryModal == '') {
      return;
    }

    dispatch(setSecondaryModal(''));
    setModalTitle(t`buy xbaa coins`);
    setModalDescription(t`Buy xBAA coins and increase your rank in the TOP-500 Leaderboard with a prize pool of $30,000! And not only this! Later you can exchange xBAA coins from the game for real $BAA tokens (10:1).`);

    openModalBoostRank();
  }, [secondaryModal]);


  const titleNormalize = (testTitle: string | number): string =>
    typeof testTitle === "number" ? testTitle.toLocaleString("en-US") : testTitle.toString();

  const headerText = useCallback((): string => {
    switch (currentModal) {
      case 'frens':
        return t`Friends`;
      case 'leaders':
        return t`Leaders`;
      case 'boost':
        return t`Boost`;
      case 'tasks':
        return t`Tasks`;
    }
    return '';
  }, [currentModal]);

  const isTasksHeader = useMemo(() => headerText() === t`Tasks`, [headerText]);
  const isLeadersHeader = useMemo(() => headerText() === t`Leaders`, [headerText]);

  // Leaders Boost Rank
  const { handleGenerateLink } = telegramShare(user);

  // path
  const gamePath = currentModal === ""

  // ==== MODAL About=====
  const { isOpen: isModalOpenAbout,
    isFadingOut: isFadingOutAbout,
    openModal: openModalAbout,
    closeModal: closeModalAbout } = useModal();

  // ==== MODAL Settings=====
  const { isOpen: isModalOpenSettings,
    isFadingOut: isFadingOutSettings,
    openModal: openModalSettings,
    closeModal: closeModalSettings } = useModal();

  // ==== MODAL Channel=====
  const { isOpen: isModalOpenChannel,
    isFadingOut: isFadingOutChannel,
    openModal: openModalChannel,
    closeModal: closeModalChannel } = useModal();

  // ==== MODAL Rules=====
  const { isOpen: isModalOpenRules,
    isFadingOut: isFadingOutRules,
    openModal: openModalRules,
    closeModal: closeModalRules } = useModal();

  // ==== MODAL Leaders - Boost Rank=====
  const { isOpen: isModalOpenBoostRank,
    isFadingOut: isFadingOutBoostRank,
    openModal: openModalBoostRank,
    closeModal: closeModalBoostRank } = useModal();

  const promo = useSelector((state: RootState) => state.promo.promo);
  const isShowPromo = useSelector((state: RootState) => state.game.showPromo);
  const showPromoModal = isShowPromo >= 5 && promo.max_uses > 0;

  return (
    <>
      {showPromoModal && <Modal blocking={true}><SecretCode /></Modal>}

      <header className={s.headerContainer}>
        <div ref={audioContainerRef} />
        {gamePath &&
          <SimpleButton imageUrl={`/images/header/btn_info.webp`} width={13.33} onClick={() => openModalAbout()} />}

        {/* Game Page */}
        {gamePath && <div className={`${s.titleBox}`} onTouchStart={() => {
          dispatch(showPromo(1));
        }}>
          <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
          <img className={s.coinsBase} src={`/images/header/coins_base.webp`} alt="title_base" />
          <img className={s.coin} src={`/images/header/coin.webp`} alt="coin" />
          <p className={s.titleGame}>{titleNormalize(user.tokens)}</p>
        </div>}

        {/* Other Pages */}
        {!gamePath && <div className={`${s.titleBox}`}>
          <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
          <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
          <p className={s.title}>{titleNormalize(headerText())}</p>
        </div>}

        {gamePath &&
          <SimpleButton imageUrl={`/images/header/btn_settings.webp`} width={13.33}
            onClick={() => openModalSettings()} />}

        {isTasksHeader &&
          <div className={s.btnAbsoluteTask}>
            <img className={s.flicker} src={`/images/task_channel_flicker.webp`} alt="flicker" />
            <SimpleButton imageUrl={`/images/task_channel_btn.webp`} width={26} onClick={openModalChannel} />
          </div>}

        {isLeadersHeader &&
          <div className={s.btnAbsolute}>
            <div className={s.btnPlate}>
              <SimpleButton imageUrl={"/images/header/yellow_btn.webp"} width={8} onClick={openModalRules} />
            </div>
          </div>}

        {/* MODAL About */}
        {isModalOpenAbout && <Modal closeModal={closeModalAbout} isFadingOut={isFadingOutAbout} blocking={true}>
          <About closeModal={closeModalAbout} />
        </Modal>}

        {/* MODAL Settings */}
        {isModalOpenSettings &&
          <Modal closeModal={closeModalSettings} isFadingOut={isFadingOutSettings} blocking={true}>
            <Settings closeModal={closeModalSettings} />
          </Modal>}

        {/* MODAL Channel */}
        {isModalOpenChannel && <Modal closeModal={closeModalChannel} isFadingOut={isFadingOutChannel} blocking={true}>
          <ModalChannelContent closeModal={closeModalChannel} />
          {/* <TechWorks /> */}
        </Modal>}

        {/* MODAL Rules */}
        {isModalOpenRules && <Modal closeModal={closeModalRules} isFadingOut={isFadingOutRules} blocking={true}>
          <ModalRulesContent closeModal={closeModalRules} />
        </Modal>}

        {/* MODAL Leaders Boost Rank BTN */}
        {isLeadersHeader && <div className={s.boostRankBtnPosition}>
          <SimpleButton className={s.boostRankBtn} onClick={() => {
            if (isFriendsLeaderboard) {
              handleGenerateLink()
            } else {
              setModalTitle(t`boost your rank`);
              setModalDescription(t`You can boost your rank on the leaderboard by buying xBAA coins. In addition, you can also invite friends, complete tasks and shear the Black Sheep daily!`)
              openModalBoostRank()
            }
          }} type="button" imageUrl={`/images/frens/invite_friends_btn.webp`}>
            <img className={s.boostRankImg}
              src={!isFriendsLeaderboard ? "/images/leaders/ref_btn_7days.webp" : "/images/tabbar/ic_tabbar_frens_act.webp"}
              alt="sheep" />
            <p className={s.boostRankBtnText}>{!isFriendsLeaderboard
              ? <Trans>boost your rank</Trans>
              : <Trans>invite friends</Trans>}</p>
          </SimpleButton>
        </div>}

        {/* MODAL Boost Rank */}
        {isModalOpenBoostRank &&
          <Modal closeModal={closeModalBoostRank} isFadingOut={isFadingOutBoostRank} blocking={true}
            allowHeader={false}>
            <ModalBoostBuyingContent closeModal={closeModalBoostRank} title={modalTitle} description={modalDescription} />
          </Modal>}
      </header>

      <div className={s.titleBox}></div>
    </>
  );
};

export default Header;
