const settingsData = [
    {
        title: "Sound",
        btn1: "Off",
        btn2: "On",
        btnBgOn: "/images/header/settings_on.webp",
        btnBgOff: "/images/header/settings_off.webp",
        icon: "/images/header/settings_sound.webp",
    },
    {
        title: "Language",
        btn1: "Ru",
        btn2: "En",
        btnBgOn: "/images/header/settings_on.webp",
        btnBgOff: "/images/header/settings_on.webp",
        icon: "/images/header/settings_language.webp",
    },
]

export default settingsData