import NavItem from "./NavItem";
import s from "./BottomNavigation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../redux/slices/gameSlice";
import { RootState } from "../../redux/store";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";

const BottomNavigation: React.FC = () => {
  const dispatch = useDispatch();

  // I hate this, but I don't have time to deal with this shit - so we just copy-paste
  const { tasks } = useSelector((state: RootState) => state.tasks);
  const { user } = useSelector((state: RootState) => state.game);

  const taskStatus = new Map<number, number>();

  if (user.completed_tasks) {
    user.completed_tasks.forEach((taskId) => {
      taskStatus.set(taskId, taskId);
    })
  }

  const handleImageClick = (modal: string) => dispatch(setCurrentModal(modal));
  const currentModal = useSelector((state: RootState) => state.game.currentModal)

  return (
    <nav className={s.bottomNav}>
      <NavItem
        path="/"
        // name="frens"
        name={t`Frens`}
        imgPath={`/images/tabbar/ic_tabbar_frens_act.webp`}
        isActive={currentModal === 'frens'}
        onClick={() => handleImageClick('frens')}
      />
      <NavItem
        path="/"
        // name="tasks"
        name={t`Tasks`}
        badge={tasks.filter((el) => !taskStatus.has(el.id)).length}
        imgPath={`/images/tabbar/ic_tabbar_earn_def.webp`}
        isActive={currentModal === 'tasks'}
        onClick={() => handleImageClick('tasks')}
      />
      <NavItem
        path="/"
        // name="game"
        name={t`Game`}
        imgPath={`/images/tabbar/ic_tabbar_game_act.webp`}
        isActive={currentModal === ''}
        onClick={() => handleImageClick('')}
      />
      <NavItem
        path="/"
        // name="boost"
        name={t`Boost`}
        imgPath={`/images/tabbar/ic_tabbar_boost_def.webp`}
        isActive={currentModal === 'boost'}
        onClick={() => handleImageClick('boost')}
      />
      <NavItem
        path="/"
        // name="leaders"
        name={t`Leaders`}
        imgPath={`/images/tabbar/ic_tabbar_rating_def.webp`}
        isActive={currentModal === 'leaders'}
        onClick={() => handleImageClick('leaders')}
      />
    </nav>
  );
};

export default BottomNavigation;
