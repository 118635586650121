// src/pages/BoostPage.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import s from "./BoostPage.module.css"
import BoostList from '../../components/BoostList';
import PageContainer from '../../components/common/PageContainer';
import { Boost, BoostStatus, BoostType } from "../../redux/types";
import telegramWebAppAPI, { telegramShare } from "../../services/telegram";
import { setClaimBoostResult } from "../../redux/slices/boostSlice";
import { setSecondaryModal, showToast } from "../../redux/slices/gameSlice";
import { Trans } from "@lingui/react/macro";

const BoostPage: React.FC = () => {
    const dispatch = useDispatch();

    const { boosts } = useSelector((state: RootState) => state.boost);
    const pageBoosts = boosts.filter((el) => el.type <= BoostType.PURCHASE_ENERGY)

    const user = useSelector((state: RootState) => state.game.user);
    const claimResult = useSelector((state: RootState) => state.boost.claimBoostResult);

    const boostsStatuses = new Map<string, BoostStatus>(Object.entries(user.boosts));

    const availableBoosts = pageBoosts.filter((el) => !boostsStatuses.has(String(el.id)) || boostsStatuses.get(String(el.id))?.status === 0);
    const claimedBoosts = pageBoosts.filter((el) => boostsStatuses.has(String(el.id)) && boostsStatuses.get(String(el.id))?.status === 1);

    // add fixed buy BAA boost
    availableBoosts.push({ id: -1, icon: "buy", reward: 0, title: "BUY xBAA COINS", description: "", type: BoostType.BUY_BAA, max_count: 999 })

    useEffect(() => {
        if (claimResult !== 1) return;

        dispatch(showToast('Boost claimed!'));
        dispatch(setClaimBoostResult(-1));
    }, [dispatch, claimResult]);

    const onClaimBoost = async (boost: Boost) => {
        const boostStatus = boostsStatuses.get(String(boost.id));

        if (boostStatus?.status === 1) return;

        if (!boostStatus || boostStatus.count < boost.max_count) {
            if (boost.type == BoostType.BUY_BAA) {
                dispatch(setSecondaryModal('boost'));
            }


            if (boost.type === BoostType.FRIENDS) {
                const { handleGenerateLink } = telegramShare(user)
                handleGenerateLink();
            }

            if (boost.type === BoostType.PURCHASE_TAPS || boost.type === BoostType.PURCHASE_ENERGY) {
                try {
                    if (!boost.invoice_url) return;

                    telegramWebAppAPI.openInvoice(boost.invoice_url, (res: string) => {
                        if (res === 'paid') {
                            // todo: need to check if transaction already completed
                            dispatch(showToast('Purchase successful!'));
                            setTimeout(() => {
                                dispatch({ type: 'game/fetchUser' });
                            }, 1000);
                        }
                    });

                } catch (error) {
                    console.error("Failed to open invoice:", error);
                }
            }

            return;
        }

        dispatch({ type: 'boost/claimBoost', payload: boost.id });
    };

    return (
        <PageContainer>
            <div className={s.scrollContainer}>
                <h1 className={s.text}><Trans>YOUR BOOSTER ZONE</Trans></h1>

                {availableBoosts.length > 0
                    ? <BoostList arrayItems={availableBoosts} status={boostsStatuses} onBoostClick={onClaimBoost} />
                    : <div className={s.stayTunedWrapper}>
                        <img className={s.stayTunedImgCloud} src={"/images/stayTunedCloud.webp"} alt="cloud" />
                        <img className={s.stayTunedImgSheep} src={"/images/stayTunedSheep.webp"} alt="sheep" />
                        {/* <img className={s.stayTunedImg} src={"/images/stay_tuned_boosts.webp"} alt="stay_tuned" /> */}
                    </div>
                }

                {claimedBoosts.length > 0 && <div className={s.titleOnBase}>
                    <p className={s.text}><Trans>claimed</Trans></p>
                </div>
                }

                <BoostList arrayItems={claimedBoosts} status={boostsStatuses} onBoostClick={onClaimBoost} />
            </div>
        </PageContainer>

    );
};

export default BoostPage;
