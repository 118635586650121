import s from "./TasksList.module.css"
import SimpleButton from "../common/SimpleButton";
import { Task } from "../../redux/types";
import ItemListBG from "../common/ItemListBG";
import { i18n } from "@lingui/core";

const getIcon = (item: Task): any => {
    switch (item.icon) {
        case "tg": return `/images/tasks/tg.webp`;
    }
}

interface TasksListItemProps {
    item: Task;
    completed: boolean;
    index: number;
    arrLength: number;
    onTaskClick: (task: Task) => void;
}

const TasksListItem: React.FC<TasksListItemProps> = ({ item, completed, index, arrLength, onTaskClick }) => {

    return (
        <div className={s.listItemBox}>
            {completed && <div className={s.opacity}></div>}

            <ItemListBG size={"big"} index={index} length={arrLength}>
                {completed && <div className={s.opacity}></div>}

                <div className={s.avaPanel}>
                    <img className={s.baseImg} src={getIcon(item)} alt="avatar" />
                </div>
                <div className={s.textContainer}>
                    <p className={s.taskText}>{i18n._(`tasks.${item.id}`)}</p>
                    <div className={s.rewardBox}>
                        <img className={s.coinImg} src={`/images/header/coin.webp`} alt="coin" />

                        <div className={s.gradientBox}>
                            <p className={s.textShadow}>{`+${item.reward}`}</p>
                            <p className={s.textGradient}
                                style={{ opacity: completed ? "50%" : "100%" }}>
                                {`+${item.reward}`}</p>
                        </div>
                    </div>
                </div>

                <div className={s.checkedBox} >
                    {completed
                        ? <img className={s.checkedImg} src={`/images/btn_checked.webp`} alt="checked" />
                        : <SimpleButton imageUrl={`/images/tasks/play.webp`} width={9.4} onClick={() => onTaskClick(item)} />
                    }
                </div>
            </ItemListBG>
        </div>
    )
}


export default TasksListItem