import PageHeader from '../common/PageHeader';
import SimpleButton from '../common/SimpleButton';
import s from './ModalRulesContent.module.css'
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";

const tableData = [
    { title: "1", value: "$300" },
    { title: "2", value: "$200" },
    { title: "3", value: "$100" },
    { title: "4", value: "$90" },
    { title: "5", value: "$85" },
    { title: "6", value: "$80" },
    { title: "7", value: "$65" },
    { title: "8", value: "$60" },
    { title: "9", value: "$55" },
    { title: "10", value: "$50" },
    { title: "11-20", value: "$25" },
    { title: "21-25", value: "$10" },
]

interface ModalRulesContentProps {
    closeModal: () => void;
}

const ModalRulesContent: React.FC<ModalRulesContentProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <img className={s.imgConfetti} src={"/images/leaders/confetti.webp"} alt="confetti" />
            <PageHeader title={t`Rules`} fontSize={1.25} />

            <div className={s.contentContainer}>
                <img className={s.imgCup} src={"/images/header/rules_cup.webp"} alt="cup" />

                <div className={s.textBox}>
                    <div className={s.gradientBox}>
                        <p className={`${s.textShadow} ${s.text15rem}`}>
                            🏆 <Trans>top 500 leaderbord</Trans></p>
                        <p className={`${s.textGradient} ${s.text15rem}`}>
                            🏆 <Trans>top 500 leaderbord</Trans></p>
                    </div>
                    <p className={s.text}><Trans>Prize Pool: $30,000 in USDT + $BAA tokens</Trans></p>
                    <p className={s.text065}><Trans>This leaderboard counts all your actions in the game! Shear the Black Sheep, invite friends, complete tasks and buy xBAA coins to increase your rank.</Trans></p>
                    <p className={s.text055}>🗓 <Trans>The date of the reward distribution will be announced very soon!</Trans></p>
                </div>

                <div className={s.textBox}>
                    <div className={s.gradientBox}>
                        <p className={`${s.textShadow} ${s.text15rem}`}>
                            🏆 <Trans>7 DAYS Leaderboard:</Trans></p>
                        <p className={`${s.textGradient} ${s.text15rem}`}>
                            🏆 <Trans>7 DAYS Leaderboard:</Trans></p>
                    </div>
                    <p className={s.text065}><Trans>This leaderboard only counts stats from invited referrals.</Trans></p>
                    <p className={s.text065}><Trans>Participate in a weekly competition with a prize pool of $1400! Invite more friends and get paid to your Solana address every week if you make it to the TOP-25!</Trans></p>

                    <div className={s.table}>
                        {tableData.map((item, index) => (
                            <div key={index} className={s.cell}>
                                <div className={s.gradientBox}>
                                    <p className={`${s.textShadow} ${s.text05rem}`}>
                                        {item.title} <Trans>place</Trans></p>
                                    <p className={`${s.textGradient} ${s.text05rem}`}>
                                        {item.title} <Trans>place</Trans></p>
                                </div>
                                <p className={s.text065}><Trans>{item.value}</Trans>!</p>
                            </div>
                        ))}
                    </div>
                    <p className={s.text04}><Trans>*The race starts at 20:00 UTC every Wednesday and ends at 20:00 UTC exactly one week later.</Trans></p>
                    <p className={s.text065}><Trans>Hurry up, you only have 7 DAYS!</Trans></p>
                </div>

                <div className={s.textBox}>
                    <p className={s.text065}>📣 <Trans>Don't forget to save the address of your Solana wallet that supports USDT and other tokens on the SOL network (click on the AIRDROP icon on the main game screen).</Trans></p>
                </div>

                <div className={s.textBox}>
                    <p className={s.text}>⚠️ <Trans>ATTENTION</Trans>!</p>
                    <p className={s.text065}><Trans>Only active referrals are counted. We strongly discourage the use of bots or fake pages, - unscrupulous participants will be banned for the use of scrambling</Trans>!</p>
                </div>
            </div>
        </div>
    )
}

export default ModalRulesContent