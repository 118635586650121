// src/services/api.ts
import axios from 'axios';
import * as Sentry from '@sentry/react';
import {Tokens} from "../redux/types";
import store from "../redux/store";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an Axios instance with default configuration
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 30000,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const authTokens: Tokens = state.game.authTokens;

        const url = new URL(config.url??'');

        // Add the access token to the headers, except for authorize and refresh endpoints
        if (url.pathname === '/refresh') {
            if (authTokens.refresh_token) {
                config.headers['Authorization'] = `${authTokens.refresh_token}`;
            }
        }

        if (url.pathname !== '/authorize' && url.pathname !== '/refresh') {
            if (authTokens.access_token) {
                config.headers['Authorization'] = `${authTokens.access_token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to capture errors and send them to Sentry
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status !== 401) {
            Sentry.captureException(error); // Capture the error with Sentry
        }
        return Promise.reject(error);
    }
);


export const authorize = async (data: any) => {
    const response = await axiosInstance.post(`${API_BASE_URL}/authorize`, JSON.stringify(data));

    return response.data;
};

export const refresh = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/refresh`);

    return response.data;
};

export const fetchProfile = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/profile`);
    return response.data;
};

export const setAddress = async (data: any) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/profile/airdrop`, JSON.stringify(data));
    return response.data;
};

export const setLanguage = async (data: any) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/profile/language`, JSON.stringify(data));
    return response.data;
};

export const updateProfile = async (data: any) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/profile`, JSON.stringify(data));
    return response.data;
}

export const fetchTasks = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/tasks`);
    return response.data;
};

export const fetchLeaderboard = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/leaders`);
    return response.data;
};

export const fetchWeeklyLeaderboard = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/weekly-leaders`);
    return response.data;
};

export const fetchMetaData = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/leaders/meta`);
    return response.data;
};

export const fetchBoosts = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/boosts`);
    return response.data;
};

export const fetchPromo = async () => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/promo`);
    return response.data;
};

export const checkAndMarkTask = async (id: number) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/task/${id}/complete`);
    return response.data;
};

export const checkAndMarkBoost = async (id: number) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/boost/${id}/complete`);
    return response.data;
};

export const claimPromo = async (code: number) => {
    const response = await axiosInstance.patch(`${API_BASE_URL}/api/promo/${code}/complete`);
    return response.data;
};

export const fetchFriends = async(level: number, start: number, stop: number) => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/profile/friends?level=${level}&start=${start}&stop=${stop}`);
    return response.data;
}


export const apiFunctionMap = {
    authorize,
    refresh,
    fetchProfile,
    updateProfile,
    fetchTasks,
    fetchLeaderboard,
    fetchWeeklyLeaderboard,
    fetchBoosts,
    checkAndMarkTask,
    checkAndMarkBoost,
    fetchPromo,
    claimPromo,
    setAddress,
    fetchFriends,
    fetchMetaData,
    setLanguage,
};

// Define the ApiFunctions type
export type ApiFunctions = typeof apiFunctionMap;