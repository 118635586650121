import SimpleButton from "../common/SimpleButton";
import settingsData from "./settings-data.js"
import s from './Settings.module.css'
import React from "react";
import SettingsList from "./SettingsList";
import PageHeader from "../common/PageHeader";
import { t } from "@lingui/core/macro";

interface SettingsProps {
    closeModal: () => void;
}

const Settings: React.FC<SettingsProps> = ({ closeModal }) => {
    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>

            <PageHeader title={t`Settings`} fontSize={1.875} />

            <div className={s.content}>
                <SettingsList arrayItems={settingsData} />
            </div>


            <p className={s.versionBox}>v1.1.4</p>
        </div>
    )
}
export default Settings
