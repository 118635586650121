import { NavLink } from "react-router-dom";
import s from "./BottomNavigation.module.css";
import { t } from "@lingui/core/macro";

interface NavItemProps {
  name: string;
  path: string;
  imgPath: string;
  isActive: boolean;
  onClick: () => void;
  badge?: number;
}

const NavItem: React.FC<NavItemProps> = ({ name, path, imgPath, isActive, onClick, badge = 0 }) => {
  return (
    <NavLink to={path} className={`${s.navItem} ${isActive ? s.gradient : ""}`} onClick={onClick}>

      {badge > 0 && <div className={`${s.badge} ${isActive ? s.badgeActive : ""}`}>{badge}</div>}

      <img className={isActive ? s.imgActive : ""} src={imgPath} alt={name} />
      <div className={s.itemName}>{t`${name}`}</div>
      {/* <div className={s.itemName}>{name}</div> */}
    </NavLink>
  );
};

export default NavItem;
