import s from './NoConnection.module.css'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Spinner from "../common/Spinner";
import { Trans } from "@lingui/react/macro";

const NoConnection = () => {
    const dispatch = useDispatch();
    const runningRequest = useSelector((state: RootState) => state.game.runningRequest);

    return (
        <div className={s.container}>
            <div className={s.titleBox}>
                <img className={s.woodenPlate} src={`/images/header/wooden_plate.webp`} alt="wooden_plate" />
                <img className={s.paper} src={`/images/header/paper.webp`} alt="paper" />
                <p className={s.titleHeader}><Trans>ooops</Trans></p>
            </div>

            <div className={s.imageBox}>
                <img className={s.noConnectionBg} src={`/images/no_connection_bg.webp`} alt="no_connection_bg" />
                <img className={s.noConnectionWifi} src={`/images/no_connection_wifi.webp`} alt="no_connection_wifi" />
            </div>

            <div className={s.textBox}>
                <p className={s.textTitle}><Trans>no connection to internet</Trans></p>
                <p className={s.text}><Trans>please check your connection</Trans></p>
            </div>

            <button className={s.btnRetry} onClick={() => { dispatch({ type: 'retryChannel' }); }} type="button">
                {runningRequest ? <Spinner /> : <p className={s.text}><Trans>Retry</Trans></p>}
            </button>
        </div>
    )
}

export default NoConnection