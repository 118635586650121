import ItemListBG from '../common/ItemListBG';
import PageHeader from '../common/PageHeader';
import SimpleButton from '../common/SimpleButton'
import Text from '../common/Text';
import s from './ModalAutotapContent.module.css'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Boost, BoostType } from "../../redux/types";
import telegramWebAppAPI from "../../services/telegram";
import { showToast } from "../../redux/slices/gameSlice";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {i18n} from "@lingui/core";

interface ModalAutoTapContentProps {
    closeModal: () => void;
}

const ModalAutoTapContent: React.FC<ModalAutoTapContentProps> = ({ closeModal }) => {
    const { boosts } = useSelector((state: RootState) => state.boost);
    const pageBoosts = boosts.filter((el) => el.type == BoostType.AUTOTAP)

    const dispatch = useDispatch();
    const purchaseBoost = (boost: Boost) => {
        if (!boost.invoice_url) return;

        telegramWebAppAPI.openInvoice(boost.invoice_url, (res: string) => {
            if (res === 'paid') {
                // todo: need to check if transaction already completed
                dispatch(showToast('Purchase successful!'));
                setTimeout(() => {
                    dispatch({ type: 'game/fetchUser' });
                }, 1000);
            }
        });

    }

    return (
        <div className={s.container}>
            <div className={s.btnClose}>
                <SimpleButton imageUrl={`/images/modalTasksContent/btn_close.webp`} width={8.89} onClick={closeModal} />
            </div>
            <PageHeader title={t`auto tap`} fontSize={2} />

            <img className={s.chickenImg} src={`/images/autotap/chicken.webp`} alt="chicken" />

            <Text fontSize={1} lineHeight={1.25} uppercase={true}><Trans>Relax, Chicko will do it all for you</Trans></Text>

            <div className={s.listContainer}>
                {pageBoosts.map((item, index) => (
                    <ItemListBG key={index} size={"big"} index={index} length={pageBoosts.length}>
                        <div className={s.listItemContainer}>
                            <img className={s.avaImg} src={`/images/autotap/${item.icon}.webp`} alt="ava" />

                            <div className={s.priceContainer}>
                                <Text fontSize={1} lineHeight={1.25} uppercase={true}>{i18n._(`boost.${item.id}`)}</Text>
                                {index > 0 && <div className={s.discountBox}>
                                    <Text fontSize={1} lineHeight={1.25} color={"--gold"} uppercase={true}>{(item.tooltip || '')}</Text>
                                </div>}
                            </div>

                            <SimpleButton className={s.starsBtn} onClick={() => { purchaseBoost(item); }} type="button" imageUrl={`/images/autotap/btn_on.webp`}>
                                <img className={s.btnStarImg} src={"/images/leaders/star.webp"} alt="star" />
                                <Text fontSize={1.25} lineHeight={1.5} color={"--white"}>{`${item.price}`}</Text>
                            </SimpleButton>
                        </div>
                    </ItemListBG>
                ))}
            </div>
        </div>
    )
}

export default ModalAutoTapContent